/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ContentPageTemplate from "../templates/content-page";
const MDXLayout = ContentPageTemplate;
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Psychoonkologie"), "\n", React.createElement(_components.p, null, "Psychoonkologie ist der Teilbereich der Psychotherapie, in dem es um die innere Verarbeitung einer Krebserkrankung geht. Es geht darum, die Diagnose in ihren verschiedenen Auswirkungen und ihrer biographischen Bedeutung zu erfassen, zu begreifen und Antworten zu finden, die es ermöglichen, mit den Belastungen der Erkrankung besser zurecht zu kommen."));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
